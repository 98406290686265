import React, { useEffect, useRef } from 'react'
import { Link } from "gatsby"

import Contact from "../components/contact"
import arrow from "../images/arrow.svg"

import getFullsizeImage from "../utils/getFullsizeImage"
import Layout from "../components/layout"
import Seo from "../components/seo"

import SectionNoBG from "../components/section_no_bg"

import section1 from "../../content/dream/section_1.json"
import section2 from "../../content/dream/section_2.json"
import principles from "../../content/dream/principles.json"
import quotes from "../../content/dream/quotes.json"
import contactTexts from "../../content/landing_page/contact.json"
import members from "../../content/team/members.json"


const Dream = () => {
  
  const section1ref = useRef(null)
  const section2ref = useRef(null)
  const section3ref = useRef(null)
  const section4ref = useRef(null)

  const qPersons = []

  useEffect(() => {
    let observer
    if (
      section1ref.current &&
      section2ref.current && 
      section3ref.current &&
      section4ref.current
    ) {
      const options = {
        threshold: .5,
      }
      observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          const navElement = document.querySelector(
            `a[href="/dream/#${entry.target.id}"]`,
          )
          if (
            entry.isIntersecting &&
            !navElement.classList.contains('active')
          ) {
            navElement.classList.add('active')
          } else if (
            !entry.isIntersecting &&
            navElement.classList.contains('active')
          ) {
            navElement.classList.remove('active')
          }
        })
      }, options)
      observer.observe(section1ref.current)
      observer.observe(section2ref.current)
      observer.observe(section3ref.current)
      observer.observe(section4ref.current)
    }
    return () => observer.disconnect()
  }, [section1ref, section2ref, section3ref, section4ref])
  

  function fetchPersonDetails (anArray){
    for( let i = 0; i < anArray.quotes.length; i++ ){
      let name = anArray.quotes[i].quote_person;
      const findData = members.persons.find((row) => row.full_name === name);
      qPersons.push(findData);
    }
  }

  fetchPersonDetails(quotes)



  return (
    <Layout pageTitle="Our dream">
      <Seo title="Our dream" description="Djäkne Startup Studio gathers people who are curious about the future and interested in helping entrepreneurs make this world a better place. Read more about us." />
      <SectionNoBG
      >
        <div className="sidebar-container d-flex flex-row">
          <div className="sidebar pe-3">
            <div className="menu d-flex flex-column">
            <Link
              to="#section-1"
              className=" arrow-button toggleColor mt-4 d-block d-md-inline-block active"
            >
              <img alt="arrow" className="mx-3" src={arrow} width="36" />
              <p className="mb-0 hover-show">{section1.headline}</p>
            </Link>
            <Link
              to="#section-2"
              className=" arrow-button toggleColor mt-4 d-block d-md-inline-block"
            >
              <img alt="arrow" className="mx-3" src={arrow} width="36" />
              <p className="mb-0 hover-show">{section2.headline}</p>
            </Link>
            <Link
              to="#section-3"
              className=" arrow-button toggleColor mt-4 d-block d-md-inline-block"
            >
              <img alt="arrow" className="mx-3" src={arrow} width="36" />
              <p className="mb-0 hover-show">{principles.headline}</p>
            </Link>
            <Link
              to="#section-4"
              className=" arrow-button toggleColor mt-4  d-block d-md-inline-block"
            >
              <img alt="arrow" className="mx-3" src={arrow} width="36" />
              <p className="mb-0 hover-show">Quotes</p>
            </Link>
            </div>
          </div>
          <div className="content px-0 pe-xl-5 me-md-5">
            <div id="section-1" ref={section1ref}>
            <SectionNoBG
              headline={section1.headline}
              text={section1.text}
              autoHeight="true"
              classes="me-xl-4"
              
              >
            
            </SectionNoBG>
            </div>
            <div id="section-2" ref={section2ref}>
            <SectionNoBG
              headline={section2.headline}
              text={section2.text}
              classes="me-xl-4"
              autoHeight="true"
              
              >
            </SectionNoBG>
            </div>
            <div id="section-3" ref={section3ref}>
            <SectionNoBG
              headline={principles.headline}
              text={principles.text}
              classes="me-xl-4"
              autoHeight="true"
              
              >
                <div className="principles mt-5">
                  {principles.principles.map((principle, index) => (
                    <div key={`principle-${index}`} className="principle me-xl-3 my-3">
                    <h4>{principle.headline}</h4>
                    <p>{principle.principle}
                    </p>
                  </div>
                  ))}
                </div>
            </SectionNoBG>
            </div>
            <div id="section-4" ref={section4ref}>           
            <SectionNoBG  classes="me-xl-4" autoHeight="true"   >
              {quotes.quotes.map((item, index) => (
                <div key={`quote-${index}`} className="quote-container my-4 py-5 ">
                  <p className="quote">{item.quote}</p>
                  <div className="d-flex flex-row-reverse mt-0 person">
                    <img src={getFullsizeImage(qPersons[index].profilePicture)} alt={item.quote_person} className="me-3 me-md-5 global-image-style quote-person-image"/>
                    <div className="me-3 d-flex flex-column justify-content-end">
                      <h4 className="mt-4 mb-0">{item.quote_person}</h4>
                      <p className="mt-0 roles">
                      {qPersons[index].roles.map((role, i) => (
                        <span className="me-2" key={`quote-${item.quote_person}-${i}`}>{role}</span>
                      ))}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </SectionNoBG>
            </div>
          </div>
        </div>
      </SectionNoBG>
      <Contact texts={contactTexts} />
    </Layout>
  )
}
export default Dream
